<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          Object.entries(this.data).length === 0 ? $t("AddNew") : $t("Update")
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <vs-card title="icon">
        <h4>* {{ $t("VehiclesType") }}</h4>
        <vs-input class="w-full" v-model="TypeNameAr" name="TypeNameAr" />

        <h4>* {{ $t("VehicleLength") }}</h4>
        <v-select
          label="Length"
          class="mt-1 w-full"
          name="LengthID"
           v-validate="'required'"
          v-model="LengthID"
          :reduce="(model) => model.ID"
          :options="VehicleLengths"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <h4>* {{ $t("Weight") }}</h4>
        <v-select
          label="UnitName"
          class="mt-1 w-full"
          name="UnitName"
           v-validate="'required'"
          v-model="WeightID"
          :reduce="(model) => model.ID"
          :options="VehicleWeights"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <h4>* {{ $t("VehiclesCategory") }}</h4>
        <v-select
          label="CategoryNameAr"
          class="mt-1 w-full"
          name="CategoryID"
           v-validate="'required'"
          v-model="CategoryID"
          :reduce="(model) => model.Id"
          :options="VehiclesCategories"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />



        <h4>* {{ $t("VehicleWidth") }}</h4>
        <v-select
          label="Width"
          class="mt-1 w-full"
          name="WidthID"
           v-validate="'required'"
          v-model="WidthID"
          :reduce="(model) => model.ID"
          :options="VehicleWidths"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <h4>* {{ $t("VehicleHeight") }}</h4>
        <v-select
          label="Height"
          class="mt-1 w-full"
          name="HeightID"
           v-validate="'required'"
          v-model="HeightID"
          :reduce="(model) => model.ID"
          :options="VehicleHeight"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <!-- Image Container -->
       <img v-if="ImageUrl" :src="ImageUrl" width="100" height="100" alt="" />
        <div class="btn-group mb-2 mt-2">
          <input
            type="file"
            ref="updateImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
        </div>
      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t("Submit") }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleVehiclesCategory from "@/store/VehiclesSetting/VehiclesCategory/moduleVehiclesCategory";
import moduleVehicleHeight from "@/store/VehiclesSetting/VehicleHeight/moduleVehicleHeight.js";
import moduleVehicleLength from "@/store/VehiclesSetting/VehicleLength/moduleVehicleLength.js";
import moduleVehicleWidth from "@/store/VehiclesSetting/VehicleWidth/moduleVehicleWidth.js";
import moduleVehicleWeight from "@/store/VehiclesSetting/VehicleWeight/moduleVehicleWeight.js";


export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          Id,
          VehiclesTypeName,
          ImageUrl,
          TypeNameAr,
          LengthID,
          WidthID,
          HeightID,
          WeightID,
          CategoryID,
        } = this.data;

        this.Id = Id;
        this.VehiclesTypeName = VehiclesTypeName;
        this.ImageUrl = ImageUrl;
        this.TypeNameAr = TypeNameAr;
        this.LengthID = LengthID;
        this.WidthID = WidthID;
        this.HeightID = HeightID;
        this.WeightID = WeightID;
        this.CategoryID = CategoryID;
      }
    },
  },

  data() {
    return {
      Id: null,
      TypeNameAr: "",
      TypeNameEn: "",
      LengthID: null,
      WidthID: null,
      HeightID: null,
      WeightID: null,
      CategoryID: null,
      ImageUrl: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    VehicleHeight() {
      return this.$store.state.VehicleHeightList.VehicleHeights;
    },
    VehicleLengths() {
      return this.$store.state.VehicleLengthList.VehicleLengths;
    },
    VehicleWidths() {
      return this.$store.state.VehicleWidthList.VehicleWidths;
    },
    VehicleWeights() {
      return this.$store.state.VehiclesWeightList.VehicleWeights;
    },
    VehiclesCategories() {
      return this.$store.state.VehiclesCategoryList.VehiclesCategories;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    },
  },

  methods: {
    initValues() {
      this.Id = null;
      this.TypeNameAr = "";
      this.VehiclesTypeName = "";
      this.LengthID = null;
      this.WidthID = null;
      this.HeightID = null;
      this.WeightID = null;
      this.CategoryID = null;
      this.ImageUrl = "";
    },
    async updateCurrImg(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        // var newFile = await FileEncryptionHandler.encrypt(file);
        //// for upload image
        this.$store
          .dispatch("VehiclesTypeList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              this.ImageUrl = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            Id: this.Id,
            TypeNameAr: this.TypeNameAr,
            WeightID: this.WeightID,
            LengthID: this.LengthID,
            HeightID: this.HeightID,
            WidthID: this.WidthID,
            IconUrl: this.IconUrl,
            CategoryID: this.CategoryID,
            ImageUrl: this.ImageUrl,
          };

          if (this.Id !== null && this.Id >= 0) {
            this.$store
              .dispatch("VehiclesTypeList/updateItem", obj)
              .then(() => {
                debugger;
                this.$store
                  .dispatch("VehiclesTypeList/fetchAllVehiclesTypeList")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.Id;
            obj.popularity = 0;
            this.$store
              .dispatch("VehiclesTypeList/addItem", obj)
              .then(() => {
                this.$store
                  .dispatch("VehiclesTypeList/fetchAllVehiclesTypeList")
                  .then(() => {
                    this.$vs.loading.close();
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },

  created() {
    this.$vs.loading();
    if (!moduleVehicleHeight.isRegistered) {
      this.$store.registerModule("VehicleHeightList", moduleVehicleHeight);
      moduleVehicleHeight.isRegistered = true;
    }

    this.$store.dispatch("VehicleHeightList/fetchList");

    if (!moduleVehicleLength.isRegistered) {
      this.$store.registerModule("VehicleLengthList", moduleVehicleLength);
      moduleVehicleLength.isRegistered = true;
    }

    this.$store.dispatch("VehicleLengthList/fetchList");

    if (!moduleVehicleWidth.isRegistered) {
      this.$store.registerModule("VehicleWidthList", moduleVehicleWidth);
      moduleVehicleWidth.isRegistered = true;
    }

    this.$store.dispatch("VehicleWidthList/fetchList");

    if (!moduleVehicleWeight.isRegistered) {
      this.$store.registerModule("VehiclesWeightList", moduleVehicleWeight);
      moduleVehicleWeight.isRegistered = true;
    }

    this.$store.dispatch("VehiclesWeightList/fetchAllVehiclesWeightList")


    if (!moduleVehiclesCategory.isRegistered) {
      this.$store.registerModule("VehiclesCategoryList", moduleVehiclesCategory);
      moduleVehiclesCategory.isRegistered = true;
    }
    this.$store.dispatch("VehiclesCategoryList/GetAllVehiclesCategories");
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
