/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_VehicleHeight(state, item) {
    state.VehicleHeights.unshift(item);
  },

  SET_VehicleHeight(state, VehicleHeight) {
    state.VehicleHeights = VehicleHeight;
  },

  UPDATE_VehicleHeight(state, item) {
    const Index = state.VehicleHeights.findIndex(p => p.ID == item.ID);
    Object.assign(state.VehicleHeights[Index], item);
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.VehicleHeights.findIndex(p => p.ID == itemId);
    state.VehicleHeights.splice(ItemIndex, 1);
  }

};
