export default {

  ADD_VehiclesCategory(state, item) {
    state.VehiclesCategory.unshift(item);
  },

  SET_VehiclesCategory(state, VehiclesCategory) {
    state.VehiclesCategories = VehiclesCategory;
  },

  UPDATE_VehiclesCategory(state, item) {
    const Index = state.VehiclesCategories.findIndex(p => p.Id == item.Id);
    Object.assign(state.VehiclesCategories[Index], item);
  },

  REMOVE_VehiclesCategory(state, itemId) {
    const ItemIndex = state.VehiclesCategories.findIndex(p => p.Id == itemId);
    state.VehiclesCategories.splice(ItemIndex, 1);
  }

};
